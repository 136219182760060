import firebase from "firebase/app";
import "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyD3tRw9v1oaEWClsIa7xoxpkstPADkPJGM",
    authDomain: "team-tegra.firebaseapp.com",
    databaseURL: "https://team-tegra.firebaseio.com",
    projectId: "team-tegra",
    storageBucket: "team-tegra.appspot.com",
    messagingSenderId: "527415786688",
    appId: "1:527415786688:web:46da06e59179979ee50125"
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
