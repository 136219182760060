import React, { useState } from "react"
import './App.css';
import SignIn from './SignIn.js';
import Dashboard from './Dashboard.js';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { AuthContext } from "./providers/UserProvider";
import PrivateRoute from './PrivateRoute';

function App() {
  const existingTokens = JSON.parse(localStorage.getItem("tokens"));
  const [authTokens, setAuthTokens] = useState(existingTokens);
  const setTokens = (data) => {
    if(data){
      localStorage.setItem("tokens", JSON.stringify(data));
    }
    else{
      localStorage.removeItem("tokens")
    }
    setAuthTokens(data);
  }

  return (
    <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
      <Router>
        <PrivateRoute exact path="/" component={Dashboard} />
        <Route path="/signin" component={SignIn} />
      </Router>
  </AuthContext.Provider>
  );
}

export default App;
